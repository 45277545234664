import { Divider, Stack, Box, Flex, Text, TextProps, StackProps, HStack } from '@chakra-ui/react'
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import useCart from 'frontastic/hooks/useCart'
import { SummaryLineItem } from '../../summary-line-item'

export const CartDrawerSummary = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { cart } = useCart()
  const {
    subtotal,
    taxes,
    coRetailDeliveryFee,
    promoDiscount,
    cartDiscountsNames,
    totalDiscountOnTotalPrice,
    total,
    isShippingMethodSelected,
    shippingPrice,
    discountedShippingPrice,
    handlingFees,
    promoCodeLoyaltyTier,
  } = getCartSummaryAmounts(cart)

  type cartTotalProps = StackProps & {
    label: string
    value?: string
    textStyle?: TextProps['textStyle'] | string
  }

  const CartTotal = ({ label, value, textStyle, ...props }: cartTotalProps) => {
    return (
      <HStack tabIndex={0} justify="space-between" mt={1} {...props}>
        <Flex align="center">
          <Text textStyle={textStyle} textColor={'text.primary'}>
            {label}
          </Text>
        </Flex>
        {value ? (
          <Text data-ge-basket-totals textStyle={textStyle} color={'text.primary'} textAlign="right">
            {value}
          </Text>
        ) : (
          <></>
        )}
      </HStack>
    )
  }

  return (
    <Box>
      <Stack spacing={1}>
        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.subtotal' })}
          value={CurrencyHelpers.formatForCurrency(subtotal)}
          textStyle="body-50"
          isSubtotal={true}
          className="subtotal"
        />

        {isShippingMethodSelected ? (
          <>
            {shippingPrice === 0 && (
              <SummaryLineItem
                label={formatMessage({ id: 'cart.summary.shipping' })}
                value={formatMessage({ id: 'checkout.shippingForm.shippingMethodFree' })}
                textStyle="body-50"
              />
            )}

            {shippingPrice !== 0 && !discountedShippingPrice && (
              <SummaryLineItem
                label={formatMessage({ id: 'cart.summary.shipping' })}
                value={CurrencyHelpers.formatForCurrency(shippingPrice)}
                textStyle="body-50"
              />
            )}

            {shippingPrice !== 0 && discountedShippingPrice && (
              <SummaryLineItem label={formatMessage({ id: 'cart.summary.shipping' })}>
                <Flex gap="5px" justifyContent="flex-end">
                  <Text textStyle={'figma-strikethrough-50'}>{CurrencyHelpers.formatForCurrency(shippingPrice)}</Text>

                  <Text textStyle={'body-50'} color="danger.600">
                    {CurrencyHelpers.formatForCurrency(discountedShippingPrice)}
                  </Text>
                </Flex>
              </SummaryLineItem>
            )}
          </>
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={formatMessage({ id: 'cart.summary.calculatedAtCheckout' })}
            textStyle="body-50"
          />
        )}

        {handlingFees !== undefined ? (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedHandlingFees' })}
            value={CurrencyHelpers.formatForCurrency(handlingFees)}
            textStyle="body-50"
            showToolTip
            toolTipMessage={formatMessage({ id: 'cart.summary.estimatedHandlingFees.toolTipMessage' })}
          />
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedHandlingFees' })}
            value={formatMessage({ id: 'cart.summary.handling.calculatedAtPayment' })}
            textStyle="body-50"
            showToolTip
            toolTipMessage={formatMessage({ id: 'cart.summary.estimatedHandlingFees.toolTipMessage' })}
          />
        )}

        {taxes !== undefined && !isNaN(taxes) ? (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.tax' })}
            value={CurrencyHelpers.formatForCurrency(taxes)}
            textStyle="body-50"
          />
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.tax' })}
            value={formatMessage({ id: 'cart.summary.handling.calculatedAtPayment' })}
            textStyle="body-50"
          />
        )}

        {coRetailDeliveryFee !== undefined && (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.coloradoretaildelivery' })}
            value={CurrencyHelpers.formatForCurrency(coRetailDeliveryFee)}
            textStyle="body-50"
            showToolTip
            toolTipMessage={formatMessage({ id: 'cart.summary.coRetailDeliveryFee.toolTipMessage' })}
          />
        )}

        {promoCodeLoyaltyTier && (
          <SummaryLineItem
            isDiscount
            doNotShowNegativeSign
            label={formatMessage({ id: 'cart.summary.loyaltyPromo' })}
            value={promoCodeLoyaltyTier}
            textStyle="body-50"
          />
        )}

        {!!totalDiscountOnTotalPrice && (
          <>
            <SummaryLineItem
              borderTop="1px solid var(--chakra-colors-shading-200)"
              isDiscount
              label={formatMessage({ id: 'cart.summary.discounts' })}
              value={CurrencyHelpers.formatForCurrency(totalDiscountOnTotalPrice)}
              textStyle="body-50"
            />

            {cartDiscountsNames.length > 0 &&
              cartDiscountsNames.map((discount, i) => (
                <SummaryLineItem
                  key={`cartDiscount-${i}`}
                  ml={3}
                  isDiscount
                  doNotShowNegativeSign
                  label={discount.discountName}
                  value={CurrencyHelpers.formatForCurrency(discount.discountValue)}
                  textStyle="body-50"
                />
              ))}

            {!!promoDiscount && (
              <SummaryLineItem
                ml={3}
                isDiscount
                doNotShowNegativeSign
                label={formatMessage({ id: 'cart.summary.promoCode' })}
                value={CurrencyHelpers.formatForCurrency(promoDiscount)}
                textStyle="body-50"
              />
            )}
          </>
        )}
      </Stack>
      <Divider mt="2" mb="3" />

      <CartTotal
        label={formatMessage({ id: 'cart.summary.estimatedTotal' })}
        value={CurrencyHelpers.formatForCurrency(total) ?? CurrencyHelpers.formatForCurrency(0)}
        textStyle="heading-desktop-100"
        marginBottom="10px"
      />
    </Box>
  )
}

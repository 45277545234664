import { useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { refCartButton } from 'atg/components/legacy-header-r2/cartButton'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import { calculateCartCount } from 'helpers_composable/utils/calculateCartCount'
import useCart from 'frontastic/hooks/useCart'
import { useLocale } from 'frontastic/contexts/localeContext'
import { CartDrawerEmptyState } from './cart-drawer-empty-state'
import { CartDrawerFooter } from './cart-drawer-footer'
import { CartDrawerSummary } from './cart-drawer-summary'
import { useComposable } from '../../composable-provider'
import { HorizontalProductCard } from '../../horizontal-product-card'

export const CartDrawer = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const { cart, removeItem, updateItem, recalculateCart } = useCart()
  const { country } = useLocale()
  const [isUpdating, setIsUpdating] = useState(false)

  const cartTotalItems = useMemo(() => {
    return cart?.cartState === 'Active' ? calculateCartCount(cart?.lineItems) : 0
  }, [cart])
  const locale = country

  const cartIsEmpty = cartTotalItems === 0 || cart?.cartState !== 'Active'

  const handleUpdate = async (updatePromise: Promise<any>) => {
    setIsUpdating(true)

    try {
      await updatePromise
    } catch (err) {
      console.error(err)
    } finally {
      await recalculateCart()
    }
    setIsUpdating(false)
  }

  const getPriceDetails = (item) => {
    const totalCustomizationCharge = item.custom?.fields?.customizationCharge?.centAmount * item.count || 0
    return {
      price: CurrencyHelpers.formatForCurrency(item.price.centAmount * item.count),
      discountedPrice: item.isDiscounted
        ? CurrencyHelpers.formatForCurrency(item.discountedPrice?.centAmount * item.count)
        : undefined,
      totalPrice: CurrencyHelpers.formatForCurrency(item.totalPrice.centAmount),
    }
  }

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const drawerBody = document.querySelector('.chakra-modal__body')
      if (drawerBody?.contains(event.target as Node)) {
        event.stopPropagation()
      }
    }
    window.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  }, [cartDrawer.isOpen])

  return (
    <Drawer
      finalFocusRef={refCartButton}
      isOpen={cartDrawer.isOpen}
      placement="right"
      onClose={cartDrawer.onClose}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 375, md: 550 }}>
        <DrawerHeader p="12px 36px">
          <DrawerCloseButton size="sm" fontSize="sm" left="xs" mt="2px" />
          <Center h="24px" fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight="1.5rem">
            <Text tabIndex={0} textStyle="heading-desktop-100">
              {formatMessage({ id: 'cart.drawer.titleCount', values: { count: cartTotalItems } })}
            </Text>
          </Center>
        </DrawerHeader>
        <Divider />
        <DrawerBody sx={{ pointerEvents: 'auto' }}>
          {!locale || !cart || cartIsEmpty ? (
            <CartDrawerEmptyState onClose={cartDrawer.onClose} />
          ) : (
            <Stack>
              <List>
                <Stack divider={<Divider />}>
                  {cart?.lineItems?.map((item, i) => {
                    const {
                      _url,
                      count,
                      isDiscounted,
                      lineItemId,
                      name,
                      variant,
                      masterData,
                      type: commerceItemClassType,
                      hemmingPriceInfo = '',
                      hemmingPrice = 0,
                      availabilityStatus,
                      custom,
                      basePrice,
                      priceMode,
                      discounts,
                      priceAllDetails,
                      price: itemPrice,
                      discountedPrice: itemDiscountedPrice,
                    } = item

                    const totalCustomizationCharge = custom?.fields?.customizationCharge?.centAmount
                      ? custom?.fields?.customizationCharge?.centAmount * count
                      : 0
                    const isDonationOrGiftCard = commerceItemClassType === 'giftCardCommerceItem'
                    const isPromoExcluded = custom?.fields?.isPromoExcluded
                    const { price, discountedPrice, totalPrice } = getPriceDetails(item)
                    const masterProductData = item?.masterData?.current?.masterVariant?.attributes
                    const availableCartDiscount = item.availableCartDiscount
                    const appliedDiscountCode = cart?.discountCodes

                    return (
                      <ListItem key={item.lineItemId}>
                        <HorizontalProductCard
                          /* General */
                          key={item.lineItemId}
                          lineItemId={item.lineItemId}
                          masterProductData={masterProductData}
                          url={item._url}
                          editable
                          columns={3}
                          size="sm"
                          name={item.name || ''}
                          quantity={item.count}
                          isOnStock={item.variant.isOnStock}
                          quantityPickerProps={{
                            max: item.variant?.availableQuantity ?? 99,
                          }}
                          variant={item.variant}
                          displayOutOfStock={item.variant.isOnStock}
                          isLoading={isUpdating}
                          usedInBagDrawer={true}
                          /* Price details */
                          price={price}
                          discountedPrice={discountedPrice}
                          basePrice={CurrencyHelpers.formatForCurrency(item.basePrice?.centAmount * item.count)}
                          promoDiscounts={{
                            discountAmount: item.isDiscounted
                              ? item?.basePrice.centAmount * item?.count - item?.totalPrice?.centAmount
                              : undefined,
                          }}
                          priceDetails={{
                            price: itemPrice,
                            discountedPrice: itemDiscountedPrice,
                          }}
                          isPromoExcluded={isPromoExcluded}
                          discounts={discounts}
                          priceAllDetails={priceAllDetails}
                          totalPrice={totalPrice}
                          isDiscounted={item.isDiscounted}
                          custom={item.custom}
                          availabilityStatus={item.availabilityStatus}
                          priceMode={item.priceMode}
                          saleEvent={availableCartDiscount?.description}
                          appliedDiscountCode={appliedDiscountCode}
                          /* Image and Functions */
                          image={{
                            src: item.variant?.images?.[0],
                            alt: item.name ?? '',
                            onClickImage: async () => {
                              await router.push(item._url)
                              cartDrawer.onClose()
                            },
                          }}
                          onRemove={handleUpdate ? () => handleUpdate(removeItem(lineItemId)) : undefined}
                          onChangeQuantity={async (val) => {
                            val === 0
                              ? await handleUpdate(removeItem(lineItemId))
                              : await handleUpdate(updateItem(lineItemId, val))
                          }}
                        />
                      </ListItem>
                    )
                  })}
                </Stack>
                <Divider mt={4} />
              </List>
              <CartDrawerSummary />
            </Stack>
          )}
        </DrawerBody>
        {cart && !cartIsEmpty && (
          <DrawerFooter p="0px 20px 16px" boxShadow="0px -2px 4px rgba(0, 0, 0, 0.1)">
            <CartDrawerFooter />
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

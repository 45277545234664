import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  Box,
  Heading,
  Center,
  Stack,
} from '@chakra-ui/react'
import { DiscountCodeState } from 'composable/helpers/utils/cart-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import useCart from 'frontastic/hooks/useCart'

interface CartPromotionAccordionProps {
  isOpen?: boolean
  showDiscount?: JSX.Element
  hideBorderTop?: boolean
  children: JSX.Element
}

export const CartPromotionAccordion = ({
  isOpen,
  showDiscount,
  hideBorderTop = false,
  children,
}: CartPromotionAccordionProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { cart } = useCart()
  const [currentCode] = cart?.discountCodes ?? [undefined]

  return (
    <Accordion
      allowToggle
      defaultIndex={isOpen || (currentCode && currentCode.state === DiscountCodeState.MatchesCart) ? [0] : undefined}
      mt={1}
    >
      <AccordionItem borderTop={hideBorderTop ? 'none' : ''}>
        {({ isExpanded }) => (
          <Box>
            <Heading as="h2">
              <AccordionButton p={'16px 4px 16px'}>
                <Center pr={'10px'}>
                  {isExpanded ? (
                    <MinusIcon fontSize="13.5px" color={'primary'} />
                  ) : (
                    <AddIcon fontSize="13.5px" color={'primary'} />
                  )}
                </Center>
                <Text
                  textStyle={'eyebrow'}
                  flex="1"
                  textAlign="left"
                  fontSize={{ base: 'xs', md: 'sm' }}
                  color={'primary'}
                  fontWeight={'700'}
                >
                  {formatMessage({
                    id: 'cart.summary.redeemAPromoCode',
                  })}
                </Text>
              </AccordionButton>
            </Heading>
            <AccordionPanel p={0}>
              <Stack spacing="2">
                {showDiscount}
                {children}
              </Stack>
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  )
}

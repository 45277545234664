import { useEffect, useState } from 'react'
import { Text, Button, Input, InputGroup, InputRightElement, HStack } from '@chakra-ui/react'
import { FrontendCartDiscount } from '@Types/cart/Discount'
import {
  DiscountCodeState,
  CouponStatus,
  DiscountCodeErrors,
  isCode13DigitCouponCode,
  checkIsLoyaltyPromo,
  getCustomerLoyaltyTier,
  PromoLoayltyStatus,
  checkIsPromoLoyaltySameAsCustomerLoyalty,
} from 'composable/helpers/utils/cart-utils'
import { COUPON_CODE } from 'helpers/constants/localStorage'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoCloseCircleOutline } from 'react-icons/io5'
import useCart from 'frontastic/hooks/useCart'
import { validateCoupon } from 'frontastic/actions/coupon'
import { utagApplyCouponCode } from 'helpers/tealiumHelper'
import { useRouter } from 'next/router'

export const CartPromotion = () => {
  const router = useRouter()
  const path = router.asPath
  const { formatMessage } = useFormat({ name: 'common' })
  const { cart, redeemDiscountCode, removeDiscountCode } = useCart()

  let [currentCode] = cart?.discountCodes ?? [undefined]
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [discountResponse, setDiscountResponse] = useState(undefined)
  const [couponStatus, setCouponStatus] = useState(undefined)
  const customerLoyaltyTier = getCustomerLoyaltyTier(cart?.customerGroup?.name)
  const isPromoLoyalty = checkIsLoyaltyPromo(currentCode?.name)

  const isPromoLoyaltySameAsCustomerLoyalty = checkIsPromoLoyaltySameAsCustomerLoyalty(
    isPromoLoyalty,
    customerLoyaltyTier,
  )
  const promoMessageForSummary =
    currentCode?.description ||
    currentCode?.cartDiscounts?.find((e: FrontendCartDiscount) => e.cartDiscountDescription)?.cartDiscountDescription ||
    currentCode?.code
  const promoPlaceHolderValue = localStorage.getItem(COUPON_CODE) || currentCode?.code || code

  const tryCodeRedemptionToCT = async (code: string) => {
    try {
      const response = await redeemDiscountCode(code)

      if (response.isError) {
        const errorInfo = JSON.parse((response as any).error.message.replace(/^Error:\s*/, ''))
        localStorage.removeItem(COUPON_CODE)
        setDiscountResponse(errorInfo)
      } else {
        utagApplyCouponCode(code)
        setDiscountResponse(response)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleApplyCode = async () => {
    if (code) {
      localStorage.removeItem(COUPON_CODE)
      setIsLoading(true)
      if (isCode13DigitCouponCode(code)) {
        // See comments at the bottom for more details
        const { couponStatus, couponCode } = await validateCoupon(code)

        setCouponStatus(couponStatus)
        if (couponCode) {
          localStorage.setItem(COUPON_CODE, code)
          await tryCodeRedemptionToCT(couponCode) // Use the Discount-code returned by the API
        }
      } else {
        await tryCodeRedemptionToCT(code)
      }
      setIsLoading(false)
    }
  }

  const handleRemoveCode = async () => {
    setIsLoading(true)

    try {
      const response = await removeDiscountCode(currentCode)
      setDiscountResponse(response)

      localStorage.removeItem(COUPON_CODE)
      setCode('')
      setCouponStatus(undefined)
      currentCode = undefined
    } catch (err) {
      console.error(err)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCode(localStorage.getItem(COUPON_CODE) || '')
    }
  }, [])

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          await handleApplyCode()
        }}
      >
        {currentCode && currentCode.state === DiscountCodeState.MatchesCart && (
          <HStack spacing={4} mb={4} justifyContent="space-between">
            <HStack>
              {!(path?.indexOf('review') > -1) && (
                <IoCloseCircleOutline cursor="pointer" style={{ fontWeight: 'bold' }} onClick={handleRemoveCode} />
              )}
              <Text textStyle="body-75">{formatMessage({ id: 'cart.summary.promoCodeApplied' })} </Text>
            </HStack>
            <Text textStyle="body-75" color="text-muted">
              {promoMessageForSummary}
            </Text>
          </HStack>
        )}

        <InputGroup size="md">
          <Input
            variant={'filled'}
            readOnly={Boolean(currentCode)}
            value={promoPlaceHolderValue}
            onChange={(e) => {
              const upperCaseCode = e.target.value.toUpperCase().trim()
              /*
                All Discount codes on CT are defined in Uppercase only
                Validating the code on CT is case-insensitive
              */
              setCode(upperCaseCode)
              setDiscountResponse(undefined)
              setCouponStatus(undefined)
            }}
            placeholder={formatMessage({ id: 'cart.coupon.title' })}
            aria-label={formatMessage({ id: 'cart.coupon.title' })}
            borderWidth={
              discountResponse?.errorType ||
              couponStatus === CouponStatus.INVALID ||
              couponStatus === CouponStatus.VALID_NON_ACTIVE
                ? '2px'
                : '1px'
            }
            borderColor={
              discountResponse?.errorType ||
              couponStatus === CouponStatus.INVALID ||
              couponStatus === CouponStatus.VALID_NON_ACTIVE
                ? 'danger.600'
                : 'shading.200'
            }
            bg="surface.primary"
          />
          {!(path?.indexOf('review') > -1) && (
            <InputRightElement w={'fit-content'}>
              {currentCode && (
                <Button variant={'solid'} minW={'4.5rem'} borderLeftRadius={0} onClick={handleRemoveCode}>
                  {formatMessage({ id: 'action.remove' })}
                </Button>
              )}
              {!currentCode && (
                <Button variant={'solid'} type="submit" minW={'4.5rem'} borderLeftRadius={0} isLoading={isLoading}>
                  {formatMessage({ id: 'cart.coupon.action.apply' })}
                </Button>
              )}
            </InputRightElement>
          )}
        </InputGroup>

        {/****** Error Messages ******/}
        {((discountResponse?.errorType === DiscountCodeErrors.DoesNotExist && code !== '') ||
          (couponStatus && couponStatus === CouponStatus.INVALID)) && (
          <Text textStyle="body-50" color="danger.600" mt={2}>
            {formatMessage({ id: 'cart.summary.promoCode.invalid', values: { promoCode: code } })}
          </Text>
        )}

        {discountResponse?.errorType === DiscountCodeErrors.TimeRangeNonApplicable && code !== '' && (
          <Text textStyle="body-50" color="danger.600" mt={2}>
            {formatMessage({
              id: 'cart.summary.promoCode.notInDateRange',
              values: {
                promoCode: code,
                validFrom: discountResponse?.validFrom,
                validUntil: discountResponse?.validUntil,
              },
            })}
          </Text>
        )}

        {discountResponse?.errorType === DiscountCodeErrors.DiscountCodeNonApplicable && code !== '' && (
          <Text textStyle="body-50" color="danger.600" mt={2}>
            {formatMessage({
              id: 'cart.summary.promoCode.discountCodeNotApplicable',
              values: {
                promoCode: code,
              },
            })}
          </Text>
        )}

        {currentCode?.state === DiscountCodeState.DoesNotMatchCart && !isPromoLoyalty && (
          <Text textStyle="body-50" color="text.success" mt={2}>
            {formatMessage({
              id: 'cart.summary.promoCode.validButCartNotEligible',
              values: { promoCode: code },
            })}
          </Text>
        )}

        {currentCode?.state === DiscountCodeState.DoesNotMatchCart && isPromoLoyalty && (
          <Text textStyle="body-50" color="text.success" mt={2}>
            {formatMessage({
              id: 'cart.summary.promoCode.validButCartNotEligible',
              values: { promoCode: code },
            })}
          </Text>
        )}

        {currentCode?.state === DiscountCodeState.DoesNotMatchCart &&
          isPromoLoyalty &&
          isPromoLoyaltySameAsCustomerLoyalty === PromoLoayltyStatus.LOYALTY_PROMO_MATCHES_CUSTOMER && (
            <Text textStyle="body-50" color={'text.success'} mt={2}>
              {formatMessage({
                id: 'cart.summary.promoCode.validButCartNotEligible',
                values: { promoCode: code, loyaltyTier: isPromoLoyalty },
              })}
            </Text>
          )}

        {currentCode?.state === DiscountCodeState.DoesNotMatchCart &&
          isPromoLoyalty &&
          isPromoLoyaltySameAsCustomerLoyalty === PromoLoayltyStatus.LOYALTY_PROMO_DOES_NOT_MATCH_CUSTOMER && (
            <Text textStyle="body-50" color={'danger.600'} mt={2}>
              {formatMessage({
                id: 'cart.summary.promoCode.loyalty.ineligible',
                values: { promoCode: code, loyaltyTier: isPromoLoyalty },
              })}
            </Text>
          )}

        {couponStatus && couponStatus === CouponStatus.VALID_NON_ACTIVE && (
          <Text textStyle="body-50" mt={2}>
            {CouponStatus.VALID_NON_ACTIVE}
          </Text>
        )}
        {/* {(path?.indexOf('review') > -1) && (
          <Text textStyle="body-50"  mt={2}>
          {CouponStatus.VALID_NON_ACTIVE}
        </Text>
          <Text textStyle="body-50" color="danger.600" mt={2}>
           NOTE: APPLY/REMOVE coupon is not allowed on review page.
          </Text>
        )} */}
      </form>
    </>
  )
}

/*
  - 13 Digit Numeric codes are Coupon Codes
    Validate the coupon code and get the actual Discount-code
  - We send real CT Discount-count code returned by the API to CT
    But for UI, we show the 13 digit code entered by the user
  - If couponStatus is NOT valid, then couponCode will be ''
  - Example:13 Digit Code: 1234567890123, Discount Code: MU1235 ON CT
  - If couponStatus is NOT valid, then couponCode will be ''
  - Once the 13 digit code is validated and applied ( Discount-code is the one that gets applied), we burn it using burnCoupon 13-digit code
  - We burn at "order submit", so that the 13-digit code is not used again
*/

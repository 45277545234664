import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Button, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { useLocale } from 'frontastic/contexts/localeContext'
import useCart from 'frontastic/hooks/useCart'
import { useComposable } from '../../composable-provider'
import { useUpdateCustomerGroup } from '../../hooks/useUpdateCustomerGroup'
import { CartPromotion } from '../cart-promotion/cart-promotion'
import { CartPromotionAccordion } from '../cart-promotion/cart-promotion-accordion'

interface CartDrawerFooterItemProps {
  label: string
  value?: string
  children?: ReactNode
}

export const CartDrawerFooter = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const { cart } = useCart()
  const { total } = getCartSummaryAmounts(cart)
  const { updateCustomerGroupIfNeeded } = useUpdateCustomerGroup()
  const { country } = useLocale()

  const navigateAndCloseDrawer = async (path: string) => {
    router.push(path)
    updateCustomerGroupIfNeeded()
    cartDrawer.onClose()
  }

  return (
    <Stack spacing="3" width="full">
      <Stack borderBottom="1px" borderColor="gray.300" spacing="4" pb={2}>
        <CartPromotionAccordion isOpen={Boolean(cart?.discountCodes?.[0]?.code)} hideBorderTop>
          <CartPromotion />
        </CartPromotionAccordion>
      </Stack>
      <Flex justify="space-between" align="center" wrap={'wrap'}>
        <VStack tabIndex={0} align="left" spacing="0">
          <Text textStyle={{ base: 'eyebrow-50', md: 'eyebrow-75' }} color={'text-primary'}>
            {formatMessage({ id: 'cart.summary.estimatedTotalShort' })}
          </Text>
          <Text data-ge-basket-totals textStyle={{ base: 'heading-desktop-75', md: 'heading-desktop-200' }}>
            {cart && CurrencyHelpers.formatForCurrency(total)}
          </Text>
        </VStack>
        <HStack flexGrow={9999} justifyContent={'flex-end'}>
          <Button
            onClick={() => navigateAndCloseDrawer('/cart')}
            variant={'outline'}
            textStyle={{ base: 'ui-button-75', md: 'ui-button-200' }}
            width={{ base: '89px', md: '132px' }}
            height={{ base: 8, md: 12 }}
          >
            {formatMessage({ id: 'action.viewBag' })}
          </Button>
          <Button
            onClick={() => navigateAndCloseDrawer(country !== 'US' ? '/checkout/international-checkout' : '/checkout')}
            variant={'solid'}
            textStyle={{ base: 'ui-button-75', md: 'ui-button-200' }}
            width={{ base: '89px', md: '132px' }}
            height={{ base: 8, md: 12 }}
            data-ge-checkout-button
          >
            {formatMessage({ id: 'action.checkout' })}
          </Button>
        </HStack>
      </Flex>
    </Stack>
  )
}
